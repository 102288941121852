import React from 'react'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'
import SEO from "../../components/seo"


export default () => (
     
  <Layout>

    <SEO title="Schedule a Demo of the Getlocal solution"
        description="See how the Getlocal solution is dedicated to building solutions for companies in travel" 
        pathname="/demo/"
    />
     
    
    <Hero 
         headerText="Schedule a demo" 
         subHeaderText="Get a free demo of the Getlocal solution with one of our experts."
         /> 

    <section className="section column is-10 is-offset-1 content-pages">
      <div className="container">
        <div className="content intro">            
            <h2>Schedule a demo </h2>
            <p>Want to see how the Getlocal solution works? Schedule a free demo with one of our experts so we can show you how the Getlocal solution works. This is a video chat that will take place via tools such as Skype or Zoom</p>
            <p>Our representative will share their screen, show you how easy it is to set up a website. We can even connect your own Bokun account for a proper demonstration. Each session will take about 30 to 60 minutes.</p>
            <div className="cta-buttons">
                <a href="https://meetings.hubspot.com/einar"  className="btn">Schedule a demo</a>
            </div>
            

        </div>

       
      </div>
    
    </section>

  </Layout>
)
